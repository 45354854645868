import React, { useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import Swiper from 'react-id-swiper';
import styled from 'styled-components';
import { Player, BigPlayButton } from 'video-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SEO from '../components/seo';
import Article from '../components/article';
import PageTitle from '../components/page-title';
import { ResponsiveCard } from '../components/cards';
import ConditionalRenderer from '../components/conditionalRenderer';

const Banner = styled.figure`
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`;

const BannerWrapper = styled.section`
  margin: 5rem 0;
`;

const Images = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  margin-bottom: 1.5rem;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    justify-content: space-between;
  }

  & > li {
    /* margin: 0; */
    flex-basis: 100%;
    display: flex;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.dimensions.mobile.med}) {
      flex-basis: 49%;
    }

    @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
      flex-basis: 24%;
    }

    img {
      width: 260px;
      cursor: pointer;
    }
  }
`;

const Title = styled(PageTitle)`
  font-family: 'Bebas Neue';
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary_dark};
  text-align: center;
  margin: 3rem 0;
`;

const CarouselWrapper = styled.section`
  .swiper-button-next,
  .swiper-button-prev {
    font-weight: 700;
    color: white;
  }
`;

function Carousel(props) {
  const { children } = props;

  const params = {
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  return (
    <CarouselWrapper>
      <Swiper {...params}>{children}</Swiper>
    </CarouselWrapper>
  );
}

function useModal() {
  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle,
  };
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: ${({ isShowing }) => (isShowing === true ? 'visible' : 'hidden')};
  z-index: 99;

  & > div {
    max-width: 640px;
    width: 90%;
    padding-bottom: 2rem;
    margin: 80px auto 1rem;

    @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
      width: 50%;
      position: absolute;
      top: calc(50% + 70px);
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }

    ul {
      list-style: circle;
    }
  }

  .modal-header {
    text-align: end;
    font-size: 1rem;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
  }

  .modal-cta {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5em;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.primary_dark};
    color: ${({ theme }) => theme.colors.white};
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    padding: 0 1rem;
    min-height: 36px;
    min-width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-close {
    cursor: pointer;
  }
`;

function Modal(props) {
  const { children, isShowing, to, hide } = props;

  const trackAction = () => {
    if (window !== undefined) {
      // if (typeof window.fbq === 'function') {
      //   window.fbq('track', 'AddToCart');
      // }
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'conversion', {
          send_to: 'AW-624869182/PRDBCPeDp9MBEL7--qkC',
        });
      }
    }
  };

  return (
    <ModalWrapper isShowing={isShowing}>
      <ResponsiveCard>
        <div className="modal-header">
          <FontAwesomeIcon
            icon={faTimes}
            width="0"
            onClick={() => hide()}
            className="modal-close"
          />
        </div>
        {children}
        <ConditionalRenderer
          condition={to && to.trim().length > 0}
          renderOnSuccess={() => (
            <div className="modal-footer">
              <a
                href={to}
                target="__blank"
                className="modal-cta"
                onClick={() => trackAction()}
              >
                Associe-se
              </a>
            </div>
          )}
        />
      </ResponsiveCard>
    </ModalWrapper>
  );
}

export default function AssocieSe(props) {
  const { pageContext } = props;
  const { page } = pageContext;
  const {
    camposAssociacao: {
      modalidades: options,
      tituloModalidades: title,
      tabela: prices,
      videoCaptacao: video,
      banner,
      linkBanner,
    },
  } = page;

  return (
    <>
      <SEO title="Associe-se" />
      <Carousel>
        {options
          .filter((item) => item.link && item.banner?.sourceUrl)
          .map((item, index) => {
            if (item.link) {
              return (
                <li key={index}>
                  <a href={item.link} target="__blank">
                    <img src={item.banner.sourceUrl} alt="" />
                  </a>
                </li>
              );
            }
            const { isShowing, toggle } = useModal();

            return (
              <li key={index}>
                <img
                  src={item.banner?.sourceUrl}
                  alt=""
                  onClick={() => toggle()}
                />
                <Modal isShowing={isShowing} hide={toggle}>
                  {ReactHtmlParser(item.info)}
                </Modal>
              </li>
            );
          })}
      </Carousel>
      <Article>
        <section>
          <Title>{title}</Title>
          <Images>
            {options
              .filter((item) => item.info && item.image?.sourceUrl)
              .map((item) => {
                const { isShowing, toggle } = useModal();
                return (
                  <li key={item.image?.sourceUrl}>
                    <img
                      src={item.image?.sourceUrl}
                      alt=""
                      onClick={() => toggle()}
                    />
                    <Modal isShowing={isShowing} hide={toggle} to={item.link}>
                      {ReactHtmlParser(item.info)}
                    </Modal>
                  </li>
                );
              })}
          </Images>
        </section>
        <ConditionalRenderer
          condition={prices}
          renderOnSuccess={() => (
            <section>
              <Banner>
                <img src={prices.sourceUrl} alt="Tabela de modalidades" />
              </Banner>
            </section>
          )}
        />
        <ConditionalRenderer
          condition={banner}
          renderOnSuccess={() => (
            <BannerWrapper>
              <a href={linkBanner} target="__blank">
                <Banner>
                  <img src={banner.sourceUrl} alt="" />
                </Banner>
              </a>
            </BannerWrapper>
          )}
        />
        <ConditionalRenderer
          condition={video}
          renderOnSuccess={() => (
            <section>
              <Player src={video.mediaItemUrl}>
                <BigPlayButton position="center" />
              </Player>
            </section>
          )}
        />
      </Article>
    </>
  );
}
